import { IBlockProps } from '../../components/types/IBlockProps';

const ProductDataGrid: IBlockProps = {
    id: "25f547c5-9baf-44bb-88c6-e094534b5e5b",
    slug: "block:product:overview",
    header: {
        id: "1b24c853-684f-498c-adcb-6046ee240d69",
        slug: "header:product",
        content: [
            {
                id: "d3d4d12f-421c-446d-9d6e-900d6a4366db",
                slug: "content:product:cta",
                title: "Let us curate the right selection",
                updatedAt: "2021-01-24 03:06:51",
            },
        ],
    },
    containers: [
        {
            id: "e7d24aff-d59b-4b32-9f71-937802285350",
            slug: "container:product:overview",
            content: [
                {
                    id: "7161bc0e-ae09-49bb-9332-950fb12da125",
                    slug: "content:product:feature:one",
                    title: "Modular, by design.",
                    text:
                        "Individually separable and customizable to fit your space, with built in stainless-steel clamps.",
                    updatedAt: "2021-01-26 12:47:59",
                },
                {
                    id: "7c4ce321-bc9b-471b-8b05-8e2d34004bb1",
                    slug: "content:product:feature:two",
                    title: "Customized, for you.",
                    text:
                        "Every set is made of the finest sourced materials to exact specifications, for your interior.",
                    updatedAt: "2021-01-26 19:42:02",
                },
                {
                    id: "911c7e99-8ac0-4fa5-8080-e20262ee0b6a",
                    slug: "content:product:feature:two",
                    title: "Designer curated, options.",
                    text:
                        "We work with our clients to provide flexibility and choice of material and size.",
                    updatedAt: "2021-01-26 19:41:57",
                },
            ],
        },
    ],
};

export default ProductDataGrid;