import * as React from "react";
import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import SocialLinks from "@components/SocialLinks";
import Subscription from "@components/Subscription";

import { useColorMode } from "theme-ui";
import { Box } from "@rebass/grid";
import { graphql, StaticQuery } from "gatsby";

import MainCoverImpl from "../@narative/gatsby-theme-novela/sections/cover/MainCoverImpl";
import CallToAction from "../components/funnel/CallToAction";
import ArticlesTitle from "../@narative/gatsby-theme-novela/components/Section/Articles.Title";
import IThemeProviderProps from "../components/types/IThemeProviderProps";
import GridContent from "../components/containers/GridContent";
import ProductCoverImpl from "../@narative/gatsby-theme-novela/sections/cover/ProductCoverImpl";
import ProductObject from "../content/ProductObject";
import { IBlockProps } from "../components/types/IBlockProps";
import ProductDetailType from "../components/types/ProductDetailType";
import IRouteProps from "../components/types/IRouteProps";

// Content
import ProductHeader from "../content/products/ProductHeader";
import ProductDataGrid from "../content/products/ProductDataGrid";


interface IProductProps extends IRouteProps, IThemeProviderProps {}

const contentQuery = graphql`
    query {
        allSite {
            edges {
                node {
                    siteMetadata {
                        name
                        social {
                            url
                            name
                        }
                    }
                }
            }
        }
    }
`;

interface IProductState {
    covers?: IBlockProps;
    mainCover?: IBlockProps;
    detail?: ProductDetailType;
}

class Product extends React.Component<IProductProps, IProductState> {
    public componentDidMount() {
        console.log("[Page ID]: ", this.props.id);

        let baseAwsS3Uri = 'https://s3.ca-central-1.amazonaws.com/static-nuvahs-edge-node-ca-central.repo.host/data-cdn';
        let coverUri = `${baseAwsS3Uri}/cover_${this.props.id}.json`;
        let mainUri = `${baseAwsS3Uri}/main_${this.props.id}.json`;
        let productUri = `${baseAwsS3Uri}/product_${this.props.id}.json`;

        fetch(coverUri)
            .then(res => {
                res.json()
                    .then(v => {
                        this.setState({ covers: v })
                    })
            });
        
        fetch(mainUri)
            .then(res => {
                res.json()
                    .then(v => {
                        this.setState({ mainCover: v })
                    })
            })
        
        fetch(productUri)
            .then(res => {
                res.json()
                    .then(v => {
                        this.setState({ detail: v })
                    })
            })
    }

    public render() {
        return this.state?.covers && this.state?.mainCover && this.state?.detail && (
            <Layout>
                <MainCoverImpl
                    cover={{
                        url: "",
                        image:
                            this.state.mainCover.containers[0].content[0].media[0]
                                .url,
                    }}
                    content={{
                        fill: this.props.fill,
                        subtitle: ProductHeader.header.content[0].subtitle,
                        title: [
                            this.state.mainCover.containers[0].content[0].title,
                        ],
                        text: this.state.mainCover.containers[0].content[0].text,
                    }}
                />

                {this.state.covers.containers.map((m) => {
                    return m.content.map((c) => {
                        return (
                            <ProductCoverImpl
                                content={{
                                    title: [c.title.toString()],
                                    subtitle: "",
                                    text: c.text,
                                }}
                                media={{
                                    placeholder: c.media[0].url,
                                }}
                            />
                        );
                    });
                })}

                <StaticQuery
                    query={contentQuery}
                    render={(data) => {
                        const {
                            social,
                        } = data.allSite.edges[0].node.siteMetadata;

                        return (
                            <>
                                <SEO
                                    pathname={`/product/${this.props.id ?? ""}`}
                                />

                                <Section narrow>
                                    <ArticlesTitle
                                        value={
                                            ProductHeader.header.content.filter(
                                                (f) =>
                                                    f.slug ==
                                                    "content:product:title"
                                            )[0]
                                        }
                                    />
                                </Section>

                                <Section>
                                    <ProductObject
                                        product={this.state.detail}
                                        fill={this.props.fill}
                                    />
                                </Section>

                                <Section>
                                    <GridContent
                                        value={ProductDataGrid}
                                        fill={this.props.fill}
                                    />
                                </Section>

                                <Section>
                                    <CallToAction />

                                    <Subscription />

                                    <Box
                                        mt={[2, 3, 5]}
                                        width={1}
                                        style={{
                                            textAlign: "center",
                                            transform: "scale(1.5)",
                                        }}
                                    >
                                        <SocialLinks links={social} />
                                    </Box>
                                </Section>
                            </>
                        );
                    }}
                />
            </Layout>
        ) || <>{'Loading...'}</>
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <Product {...props} fill={fill} />;
};

export default StateWrapper(Product);
