import { IBlockProps } from '../../components/types/IBlockProps';

const ProductHeader: IBlockProps = {
    id: "25f547c5-9baf-44bb-88c6-e094534b5e5b",
    slug: "block:product:overview",
    header: {
        id: "1b24c853-684f-498c-adcb-6046ee240d69",
        slug: "header:product",
        content: [
            {
                id: "b9bb0cb6-d9aa-40f1-bd7d-a74582b7a30b",
                slug: "content:product:title",
                media: [
                    {
                        url: "",
                        placeholder: "",
                    },
                ],
                subtitle: "Hand Made in Canada",
                title: "Design Yours",
                text:
                    "Made to order modular sofa set with hand stiched italian leather and refined woods.",
                updatedAt: "2021-01-26 12:42:47",
            },
        ],
    },
    containers: [],
};

export default ProductHeader;