import * as React from "react";
import { useColorMode } from "theme-ui";
import { Box, Flex } from "@rebass/grid";
import { Text, Image, Link } from "rebass";

import styled from "styled-components";
import themeProps from "../gatsby-plugin-theme-ui/index";
import ActionButton from "../components/objects/ActionButton";
import ProductDetailType from "../components/types/ProductDetailType";

interface IProductObjectProps {
    product: ProductDetailType;
    fill: string;
}

class ProductObject extends React.Component<IProductObjectProps> {
    public render() {
        return (
            <Flex flexWrap={"wrap"} m={[2, 3, 5]}>
                <Box width={[1, 2 / 3]} p={[2, 3]}>
                    <ImageContainer
                        src={this.props.product.media}
                        alt={this.props.product.title}
                    />
                </Box>
                <Box width={[1, 1 / 3]} p={[2, 3]} color={this.props.fill}>
                    <Title p={[2, 3]}>{this.props.product.title}</Title>
                    <PriceLabel p={[2, 3]}>
                        ${this.props.product.price}
                    </PriceLabel>
                    <TextContainer p={[2, 3]}>
                        {this.props.product.text}
                    </TextContainer>
                    <Link href={this.props.product.route}>
                        <CTAButton>{"Customize"}</CTAButton>
                    </Link>
                </Box>
            </Flex>
        );
    }
}

const ImageContainer = styled(Image)`
    border-radius: 15px;
`;

const Title = styled(Text)`
    font-family: ${themeProps.fonts.serif};
    font-size: 24px;
    font-weight: 500;
`;

const PriceLabel = styled(Text)`
    font-family: ${themeProps.fonts.serif};
    font-size: 48px;
    font-weight: 100;
`;

const TextContainer = styled(Text)`
    font-size: 16px;
    font-weight: 100;
    line-height: 2;
`;

const CTAButton = styled(ActionButton)`
    /* Action */

    /* Style Props */
    box-shadow: 0px 50px 150px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    /* Font */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 42px;
    /* or 262% */

    letter-spacing: 16px;
    text-transform: uppercase;
    text-align: center;

    transition: ease-in-out 100ms;

    &:hover {
        transform: translate(0, -5%);
    }

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 49px 0px;
`;

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <ProductObject {...props} fill={fill} />;
};

export default StateWrapper(ProductObject);
